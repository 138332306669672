import React from 'react';

// import { Navbar, Home, Process, About, Portfolio, Skills, Services, Modals, Testimonials, Partners, Blog, BlogModals, Subscribe, Contact, Map, Footer } from './components'; 
import { Navbar, Home,  About, Portfolio,  Modals,  Contact,  Footer } from './components';      
const App = () => {
  // const src1=process.env.PUBLIC_URL + '/assets/js/jquery-3.5.1.min.js'
  // const src2=process.env.PUBLIC_URL + '/assets/vendor/appear/jquery.appear.js'
  // const src3=process.env.PUBLIC_URL + '/assets/vendor/bootstrap/js/bootstrap.bundle.min.js'
  // const src4=process.env.PUBLIC_URL + '/assets/vendor/one-page/scrollIt.min.js'
  // const src5=process.env.PUBLIC_URL + '/assets/js/custom.js'
  // const src6=process.env.PUBLIC_URL + '/assets/js/dark-light.js'
  return (
    <>
  <Navbar />
  <Home />
  {/* <Process /> */}
  <About />
  <Portfolio />
  {/* <Skills /> */}
  {/* <Services /> */}

  <Modals />
  {/* <Testimonials /> */}
  {/* <Partners /> */}
  {/* <Blog />
  <BlogModals />
  <Subscribe /> */}
  <Contact />

  {/* <Map /> */}
  <Footer />
 

</>
  )
}

export default App