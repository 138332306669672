import React from 'react'

const About = () => {
  return (
    <section className='md:pt-[112px] pt-16 relative' id='about'>
        <div className='container'>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-[90px] items-center'>
                <div className='relative space-y-4 md:space-y-0'>
                    <div>
                        <img src='assets/img/about/1.png' className='mx-auto dark:hidden' alt='' />
                        <img src='assets/img/about/2.png' className='mx-auto hidden dark:block' alt='' />
                    </div>
                    <div className='top-[120px] -left-20 md:absolute'>
                        <div className='flex gap-[17px] items-center pt-[25px] pr-[30px] pb-[21px] pl-[26px] bg-white dark:bg-semilight shadow-[25px_0px_65px_rgba(54,32,152,0.11)] rounded-lg'>
                            <h3 className='text-[50px] text-[#1cbe59]'><span className='dizme_tm_counter stop' data-from='0' data-to='18' data-speed='2000'>4</span>+</h3>
                            <span className='name text-lg font-secondary leading-[1.3] font-medium'>
                                Yıllık<br />
                                Tecrübe
                            </span>
                        </div>
                    </div>
                    <div className='bottom-[70px] -right-[30px] md:absolute'>
                        <div className='flex gap-[17px] items-center pt-[25px] pr-[30px] pb-[21px] pl-[26px] bg-white dark:bg-semilight shadow-[25px_0px_65px_rgba(54,32,152,0.11)] rounded-lg'>
                            <h3 className='text-[50px] text-[#6e50f0] dark:text-[#f0c45c]'><span className='dizme_tm_counter stop' data-from='0' data-to='9' data-speed='2000'>15</span>+</h3>
                            <span className='name text-lg font-secondary leading-[1.3] font-medium'>
                                Proje<br />
                                Deneyimi
                            </span>
                        </div>
                    </div>
                </div>
                <div className='text-center md:text-left'>
                    <div className='mb-[25px] lg:max-w-[440px]'>
                        <p className='text-custom text-xl md:text-2xl font-medium font-secondary wow fadeInUp' data-wow-duration='1s'>I'm a Full Stack Developer</p>
                        <h3 className='md:text-[48px] text-[35px] leading-[1.25] mt-1.5 wow fadeInUp' data-wow-duration='1s'>Ben, profesyonel bir şekilde web uygulamaları geliştirebilirim</h3>
                    </div>
                    <p className='text-muted dark:text-darkmuted lg:max-w-[485px] leading-[30px] mb-[47px] wow fadeInUp' data-wow-duration='1s'>
                    Eğitim hayatım ve tecrübelerim doğrultusunda farklı insanlarla birlikte çalışma, bir takımda yer alma ve birlikte üretebilmeyi öğrenme fırsatım oldu. Yeni şeyler öğrenmeyi, yeteneklerimi geliştirmeyi çok önemsiyor, bu amaçla insan ilişkilerinin öğreticiliğinden faydalanmak gerektiğini düşünüyorum. Ekibimdeki insanlarla bilgi alışverişinde bulunmak benim için bu nedenle çok önemli.Fikir geliştirmenin zihni, dolayısıyla bedeni sürekli aktif tuttuğunun bilincinde olarak hem iş hem de bireysel hayatta yaratıcı olmanın önemli olduğunu düşünüyorum.
                    </p>
                    <div className='wow fadeInUp' data-wow-duration='1s'>
                        <a href='#contact' className='btn-custom'>İletişime geç</a>
                    </div>
                </div>
            </div>
        </div>
        <div className='left-10 top-5 absolute hidden lg:block wow fadeInLeft' data-wow-duration='1s'><img src='assets/img/brushes/about/1.png' alt='' /></div>
        <div className='-bottom-[50px] right-0 absolute hidden lg:block wow fadeInRight' data-wow-duration='1s'><img src='assets/img/brushes/about/2.png' alt='' /></div>
    </section>
  )
}

export default About