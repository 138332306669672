import React from 'react'

const Portfolio = () => {
  return (
    <section className='pt-[113px] md:pt-[185px] pb-[88px] md:pb-[165px] relative' id='portfolio'>
        <div className='container relative z-10'>
            <div className='grid grid-cols-1'>
                <div className='text-center max-w-[680px] mx-auto'>
                    <p className='text-custom text-[20px] md:text-2xl font-medium font-secondary'>Portföy</p>
                    <h3 className='md:text-[48px] text-[35px] leading-[1.25] mb-[23px] mt-1.5'>Harika Çalışmalarım</h3>
                    <p className='text-muted dark:text-darkmuted text-lg leading-[30px]'>Masaüstünde iyi çalışan web siteleri tasarlamanın en yaygın yöntemleri duyarlı ve uyarlanabilir tasarımdır.</p>
                </div>
            </div>
            <div className='grid grid-cols-1'>
                <div className='md:pt-[78px] pt-[53px] pb-[46px] md:pb-[56px]'>
                    <ul className='flex items-center gap-4 flex-wrap lg:gap-[50px] justify-center' id='menu-filter'>
                        <li className='shrink-0'>
                            <a href='#' className='hover:text-custom active text-base duration-300' data-filter='*'>Hepsi</a>
                        </li>
                        <li className='shrink-0'>
                            <a href='#' className='hover:text-custom text-base duration-300' data-filter='.eticaret'>E-Ticaret</a>
                        </li>
                        <li className='shrink-0'>
                            <a href='#' className='hover:text-custom text-base duration-300' data-filter='.blog'>Blog</a>
                        </li>
                        <li className='shrink-0'>
                            <a href='#' className='hover:text-custom text-base duration-300' data-filter='.servis'>Teknik Servis</a>
                        </li>
                        <li className='shrink-0'>
                            <a href='#' className='hover:text-custom text-base duration-300' data-filter='.kurumsal'>Kurumsal</a>
                        </li>
                        {/* <li className='shrink-0'>
                            <a href='#' className='hover:text-custom text-base duration-300' data-filter='.lifestyle'>Lifestyle</a>
                        </li> */}
                    </ul>
                </div>
            </div>
            <div className='dizme_tm_portfolio_titles'></div>
            <div className='md:flex work-filter wow fadeInUp' data-wow-duration='1s'>
                <div className='lg:w-1/3 md:w-1/3 p-3 eticaret'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Anasayfa' data-category='B2B Web Sitesi'>
                            <a className='img-zoom relative' href='assets/img/portfolio/1.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/1.webp' className='object-cover w-full h-full' alt='B2B Web Sitesi | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 eticaret'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Ürünler' data-category='B2B Web Site'>
                            <a className='img-zoom relative' href='assets/img/portfolio/2.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/2.webp' className='object-cover w-full h-full' alt='B2B Web Sitesi | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 eticaret'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Hakkımızda Sayfası' data-category='B2B Web Site'>
                            <a className='img-zoom relative' href='assets/img/portfolio/3.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/3.webp' className='object-cover w-full h-full' alt='B2B Web Sitesi | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 blog'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Anasayfa' data-category='Kişisel Blog Sitem'>
                            <a className='img-zoom relative' href='assets/img/portfolio/4.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/4.webp' className='object-cover w-full h-full' alt='Kişisel Blog Sitem | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 blog'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Footer' data-category='Kişisel Blog Sitem'>
                            <a className='img-zoom relative' href='assets/img/portfolio/5.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/5.webp' className='object-cover w-full h-full' alt='Kişisel Blog Sitem | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 blog'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Blog Liste Sayfası' data-category='Kişisel Blog Sitem'>
                            <a className='img-zoom relative' href='assets/img/portfolio/6.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/6.webp' className='object-cover w-full h-full' alt='Kişisel Blog Sitem | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 servis'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Login Sayfası' data-category='Teknik Servis Projem'>
                            <a className='img-zoom relative' href='assets/img/portfolio/7.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/7.webp' className='object-cover w-full h-full' alt='Teknik Servis Projem | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 servis'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Ayarlar Sayfası' data-category='Teknik Servis Projem'>
                            <a className='img-zoom relative' href='assets/img/portfolio/8.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/8.webp' className='object-cover w-full h-full' alt='Teknik Servis Projem | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 servis'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='İş Akışı Sayfası' data-category='Teknik Servis Projem'>
                            <a className='img-zoom relative' href='assets/img/portfolio/9.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/9.webp' className='object-cover w-full h-full' alt='Teknik Servis Projem | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 kurumsal'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Blended Games' data-category='Kurumsal Web Site'>
                            <a className='img-zoom relative' href='assets/img/portfolio/11.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/11.webp' className='object-cover w-full h-full' alt='Blended Games Kurumsal | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 kurumsal'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Blended Games' data-category='Kurumsal Web Site'>
                            <a className='img-zoom relative' href='assets/img/portfolio/12.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/12.webp' className='object-cover w-full h-full' alt='Blended Games Kurumsal | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 kurumsal'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Novicars' data-category='Kurumsal Web Site'>
                            <a className='img-zoom relative' href='assets/img/portfolio/13.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/13.webp' className='object-cover w-full h-full' alt='Novicars Kurumsal | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/3 md:w-1/3 p-3 kurumsal'>
                    <div className='rounded-xl overflow-hidden group relative'>
                        <div className='main-img-box' data-title='Novicars' data-category='Kurumsal Web Site'>
                            <a className='img-zoom relative' href='assets/img/portfolio/14.webp'>
                                <div className='main group-hover:scale-110 overflow-hidden'>
                                    <img src='assets/img/portfolio/14.webp' className='object-cover w-full h-full' alt='Novicars Kurumsal | Adnan CIMIK' />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='left-[50px] top-[150px] absolute hidden lg:block wow zoomIn' data-wow-duration='1s'><img src='assets/img/brushes/portfolio/1.webp' alt='' /></div>
        <div className='right-[10px] bottom-[51px] absolute hidden lg:block wow fadeInRight' data-wow-duration='1s'><img src='assets/img/brushes/portfolio/2.webp' alt='' /></div>
    </section>
  )
}

export default Portfolio