import React from 'react'

const Modals = () => {
  return (
    <section>
        <div>
            <div className='hidden overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center' id='modal-id'>
                <div className='relative w-full my-10 mx-auto max-w-[968px] h-[calc(100vh-80px)] py-20'>
                    <div className='rounded relative flex flex-col w-full pt-5 overflow-y-auto p-[50px] bg-white dark:bg-[#34353a] outline-none focus:outline-none h-full scrollbar-thumb-dark/25 scrollbar-track-dark/10 scrollbar-thin'>
                        <div className='flex items-center justify-between pb-5'>
                            <h3 className='text-2xl font-semibold'>
                                Services Details
                            </h3>
                            <button className='modal-buttons-toggle ml-auto bg-transparent border-0 dark:text-white text-dark'  toggle-value='modal-id'  >
                                <span className='bg-transparent stroke-dark block'>
                                    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-7 h-7 dark:stroke-white stroke-dark'>
                                        <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div>
                            <img src='assets/img/service/1.jpg' className='h-[350px] object-cover w-full rounded-xl' alt='' />
                            <h3 className='mt-[37px] text-[30px] font-medium '>Creative Design</h3>
                            <p className='text-[21px] font-secondary font-medium mt-1'>Starts from <span className='text-custom text-2xl'>$99</span></p>
                            <div className='mt-[23px] space-y-5'>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hidden fixed inset-0 z-40 bg-dark/90' id='modal-id-backdrop'></div>
        </div>
        <div>
            <div className='hidden overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center' id='modal-id-two'>
                <div className='relative w-full my-10 mx-auto max-w-[968px] h-[calc(100vh-80px)] py-20'>
                    <div className='rounded relative flex flex-col w-full pt-5 overflow-y-auto p-[50px] bg-white dark:bg-[#34353a] outline-none focus:outline-none h-full scrollbar-thumb-dark/25 scrollbar-track-dark/10 scrollbar-thin'>
                        <div className='flex items-center justify-between pb-5'>
                            <h3 className='text-2xl font-semibold'>
                                Services Details
                            </h3>
                            <button className='modal-buttons-toggle ml-auto bg-transparent border-0 text-dark'  toggle-value='modal-id-two'  >
                                <span className='bg-transparent stroke-dark block'>
                                    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-7 h-7 dark:stroke-white stroke-dark'>
                                        <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div>
                            <img src='assets/img/service/2.jpg' className='h-[350px] object-cover w-full rounded-xl' alt='' />
                            <h3 className='mt-[37px] text-[30px] font-medium '>Graphic Design</h3>
                            <p className='text-[21px] font-secondary font-medium mt-1'>Starts from <span className='text-custom text-2xl'>$199</span></p>
                            <div className='mt-[23px] space-y-5'>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hidden fixed inset-0 z-40 bg-dark/90' id='modal-id-two-backdrop'></div>
        </div>
        <div>
            <div className='hidden overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center' id='modal-id-three'>
                <div className='relative w-full my-10 mx-auto max-w-[968px] h-[calc(100vh-80px)] py-20'>
                    <div className='rounded relative flex flex-col w-full pt-5 overflow-y-auto p-[50px] bg-white dark:bg-[#34353a] outline-none focus:outline-none h-full scrollbar-thumb-dark/25 scrollbar-track-dark/10 scrollbar-thin'>
                        <div className='flex items-center justify-between pb-5'>
                            <h3 className='text-2xl font-semibold'>
                                Services Details
                            </h3>
                            <button className='modal-buttons-toggle ml-auto bg-transparent border-0 text-dark'  toggle-value='modal-id-three'  >
                                <span className='bg-transparent stroke-dark block'>
                                    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-7 h-7 dark:stroke-white stroke-dark'>
                                        <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div>
                            <img src='assets/img/service/3.jpg' className='h-[350px] object-cover w-full rounded-xl' alt='' />
                            <h3 className='mt-[37px] text-[30px] font-medium '>UI/UX Design</h3>
                            <p className='text-[21px] font-secondary font-medium mt-1'>Starts from <span className='text-custom text-2xl'>$299</span></p>
                            <div className='mt-[23px] space-y-5'>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hidden fixed inset-0 z-40 bg-dark/90' id='modal-id-three-backdrop'></div>
        </div>
        <div>
            <div className='hidden overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center' id='modal-id-four'>
                <div className='relative w-full my-10 mx-auto max-w-[968px] h-[calc(100vh-80px)] py-20'>
                    <div className='rounded relative flex flex-col w-full pt-5 overflow-y-auto p-[50px] bg-white dark:bg-[#34353a] outline-none focus:outline-none h-full scrollbar-thumb-dark/25 scrollbar-track-dark/10 scrollbar-thin'>
                        <div className='flex items-center justify-between pb-5'>
                            <h3 className='text-2xl font-semibold'>
                                Services Details
                            </h3>
                            <button className='modal-buttons-toggle ml-auto bg-transparent border-0 text-dark'  toggle-value='modal-id-four'  >
                                <span className='bg-transparent stroke-dark block'>
                                    <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor' className='w-7 h-7 dark:stroke-white stroke-dark'>
                                        <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div>
                            <img src='assets/img/service/4.jpg' className='h-[350px] object-cover w-full rounded-xl' alt='' />
                            <h3 className='mt-[37px] text-[30px] font-medium '>Web Design</h3>
                            <p className='text-[21px] font-secondary font-medium mt-1'>Starts from <span className='text-custom text-2xl'>$399</span></p>
                            <div className='mt-[23px] space-y-5'>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                                <p className='text-muted dark:text-darkmuted'>Dizme is a leading web design agency with an award-winning design team that creates innovative, effective websites that capture your brand, improve your conversion rates, and maximize your revenue to help grow your business and achieve your goals.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='hidden fixed inset-0 z-40 bg-dark/90' id='modal-id-four-backdrop'></div>
        </div>
    </section>
  )
}

export default Modals